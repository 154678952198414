<template lang="pug">
  div
    Loading(v-if="isLoading")
    .plane-background(v-if="!isLoading & !isDataExist")
    .container-fluid.wrapper(v-if="isDataExist")
      #title(@click="callRockyInfo()")
        | {{rockyName}} <br>
        small {{this.rockName}}
      #arrow
        .btn-link(@click.prevent="callRouteList")
          i.fas.fa-angle-left 
          | &nbsp;戻る
      //-ルート画像カルーセル
      carousel(
        v-bind:per-page-custom=[[100,3],[768,7]]
        :navigation-enabled="false"
        :pagination-enabled="true"
        navigation-prev-label="〈"
        navigation-next-label="〉"
        :speed="1000")
        slide(v-for="record in this.rockImgList" :class="{currentImg:record.isCurrent}")
          img.slider-inner(:src="makeImgThumbPath(record.img)" alt="" @click="changeImg(record)")
      //-カルーセルの選択対象により、切り替え表示されるエリア
      .row#route-canvas-row
        .col-12.col-md-6
          #canvas-header {{imgHeaderMsg}}
          #canvas-container-wrapper
            #canvas-container
              //-岩画像表示canvas
              canvas(width="800" height="500")#rocky-img-canvas
              //-画像上の全ルート表示用canvas
              canvas(width="800" height="500")#route-canvas
              //-ルート線のハイライト表示用canvas
              canvas(width="800" height="500")#route-highlight-canvas
        .col-12.col-md-6#list-button-container
          #info-container(v-if="isShowInfo")
            .info(v-for="info in this.remarkList")
              i.fas.fa-info-circle 
              |{{info}}
          #route-list-container
            //-ルート名一覧
            b-list-group.small
              b-list-group-item(v-for="route in dispRouteList" v-on:mouseover="routeHighlight(route)" @click="routeHighlight(route)" :class="{listGroupItemActive:route.isRouteActive}")     
                .list-innner
                  .list-no {{route.no}}
                  .list-route-name {{route.routeName}}
                  .list-level {{route.level}}
                  //記録作成アイコン
                  .list-make-record(ontouchstart="" v-if="route.isShowMakeRecordIcon")
                    .inner-box
                      router-link(:to="{ name: 'record_edit', query: { rockyId:rockyId, rockId:rockId, routeId:route.routeId, ref:'routeId'}}")
                        i.fas.fa-edit
                        span.list-make-recordtext 記録を作成する

            //-ルート表示ボタン
            #draw-route-button-area(v-if="isShowDrawRouteButton")
              b-button.button-blue#draw-route-button(@click="switchDrawRoute()") 
                div(v-if="isShowingRouteLine")
                  |ルート線を非表示にする
                div(v-else)
                  |ルート線を表示する
</template>

<script>
import Loading from '@/components/Loading.vue'
import { Carousel, Slide } from 'vue-carousel'
import axios from 'axios'

export default {
  // データオブジェクト
  data() {
    return {
      isLoading:true,
      isDataExist:false,
      // ルート線表示・非表示のフラグ
      isShowingRouteLine: true,
      // APIから取得したルート情報
      routeDataListJson:[],
      // 遷移元から受け取る岩情報
      rockyDetailJson:'',
      rockyId:'',
      rockyName:'',
      rockId:'',
      rockName:'',
      routeNo:'',
      routeId:'',
      routeName:'',//使っていない
      routeLevel:'',//使っていない
      isLogin:false,
      dbRouteList:[],
      // ルート一覧List
      dispRouteList:[],
      rockImgList: [],
      routeIdNameList:[],
      currentDispRouteImg:'',
      currentRouteList:[],
      highlightRouteObj:null,
      imgHeaderMsg:'',
      isShowCarousel:false,
      isShowInfo:false,
      isShowDrawRouteButton:false,
      remarkList:[],
      imgOrignalWidth:0,
      imgOrignalHeight:0,
      //ルート線のstyle
      ROUTE_LINE_COLOR:'#ebebeb',
      ROUTE_LINE_HIGHLIGHT_COLER:'#0596ff',
      ROUTE_LINE_WIDTH:1,
      ROUTE_LINE_HIGHLIGHT_WIDTH:3,
      //canvas上のラベルのstyle
      LABEL_COLOR:'rgba(47,47,47,0.7)',
      LABEL_HIGHLIGHT_COLER:'#0596ff',
      LABEL_TEXT_COLOR:'#ebebeb',
      LABEL_TEXT_HIGHLIGHT_COLOR:'#FAFAD2',
      LABEL_STYLE:'bold 15px sans-serif'
    }
  },
  watch: {
    '$route' (to, from) {
      // console.log('ルート画像画面のwatch')
    }
  },
  // インスタンス破棄前
  beforeDestroy() {
    // console.log('ルート画面のbeforeDestroy')
  },
  // インスタンス作成後
  mounted() {

    // console.log('ルート画面のcreated')
    this.rockyId = this.$route.params.rockyId
    this.rockId = this.$route.params.rockId
    this.routeId = this.$route.params.routeId
    this.rockyDetailJson = this.$route.params.rockyDetailJson

    this.isLogin = this.$user.isLogin
    // 御岳ボルダー以外の岩場だった場合
    if(this.rockyId != 14) {
      //未ログインの場合
      if(!this.$user.isLogin) {
        this.$router.push({ name: 'login', query: { url:this.$route.fullPath }})
        return
      }else if(!this.$user.payment){
        //有料会員でない場合
        this.$router.push('/')
        return
      }
    }
    this.mountedProcess()
  },
  // データ変更後
  updated() {
    // console.log('ルート画面のupdated')
  },
  // メソッド
  methods: {
    /*--------------------------
      mounted時の処理
    --------------------------*/ 
    async mountedProcess(){
      //ルートデータ取得
      await this.getRockyMasterAndRouteDataList()
      //ルート座標データ取得
      await this.getRouteCoordinateData()

      const drawRoute = () => {
        //canvasに描画する
        this.callDrawRoute()
      }

      //画像表示
      this.drawImageCanvas(this.currentDispRouteImg,drawRoute)

      //特記事項がある場合は表示する
      if(this.remarkList.length > 0){
        this.isShowInfo = true
      }

      this.isLoading = false
    },
    /*--------------------------
      canvasに岩画像を表示する
    --------------------------*/    
    async drawImageCanvas(imgPath,drawRoute) {

      let img = new Image()

      img.onload = function() {

        //function()の外に実装するとIE11でgetElementById()がnullになる為、ここに実装
        let parent = document.getElementById('canvas-container')
        let imgCanvas = document.getElementById('rocky-img-canvas')
        let routeCanvas = document.getElementById('route-canvas')
        let highlightCanvas = document.getElementById('route-highlight-canvas')

        // 親ボックスのサイズをセットする
        let canvasWidth = parent.clientWidth
        let canvasHeight = parent.clientHeight
        var element = new Image()

        let ctx = imgCanvas.getContext('2d')


        //画像のオリジナルサイズ
        let orgWidth  = img.width
        let orgHeight = img.height
        this.imgOrignalWidth = orgWidth 
        this.imgOrignalHeight = orgHeight

        //ルート名を表示するヘッダー要素の幅を最大幅とする
        var obj = document.getElementById('canvas-header')
        var headerWidth = obj.getBoundingClientRect().width

        //縦横比を保持したまま、横幅に合わせてリサイズする
        let resizeWidth = headerWidth
        let resizeHeight = orgHeight * ( resizeWidth / orgWidth  )

        //横長の画像の場合は著しく縮んで表示されるのを防ぐ為に
        //横スクロールで表示できるよう調整する
        if(orgWidth > orgHeight * 1.5){
          //縦の高さを固定にして、縦横比を保持したままリサイズする。
          resizeWidth = 300 * ( orgWidth / orgHeight ) 
          resizeHeight = 300
        }

        //canvasをリサイズ
        imgCanvas.width = resizeWidth
        imgCanvas.height = resizeHeight
        routeCanvas.width = resizeWidth
        routeCanvas.height =resizeHeight
        highlightCanvas.width = resizeWidth
        highlightCanvas.height = resizeHeight
        //canvasのサイズが確定したこのタイミングでcontainer、wrapperもリサイズする
        let strResizeHeight = resizeHeight + 'px'
        let strResizeWidth = resizeWidth + 'px'
        document.getElementById('canvas-container-wrapper').style.height = strResizeHeight 
        document.getElementById('canvas-container-wrapper').style.width = strResizeWidth
        document.getElementById('canvas-container').style.height = strResizeHeight
        document.getElementById('canvas-container').style.width = strResizeWidth

        //画像表示
        ctx.drawImage(img, 0, 0, resizeWidth, resizeHeight)
        //ルート線を描画
        drawRoute()

      }
      img.src = process.env.VUE_APP_MATERIAL_URL + imgPath
    },

    /*--------------------------
      カルーセルで画像選択した際の処理
    --------------------------*/ 
    changeImg(record){
      this.imgHeaderMsg = 'ルート名：' 
      //canvasの画像を切り替える
      this.currentDispRouteImg = record.img

      const drawRoute = () => {
        //画像上の全ルート線を表示する
        this.isShowingRouteLine = true
        this.drawAllRoute(record.coordinateList)
      }

      this.drawImageCanvas(record.img,drawRoute)

      //画面上のルート一覧を更新する
      this.dispRouteList = this.makeRouteInfoList(record.coordinateList,'')
      //currentフラグを切り替える
      let wkList = []
      for (let i = 0; i < this.rockImgList.length; i++) {
        let wkObj = this.rockImgList[i]
        wkList.push({
          'img':wkObj.img,
          'coordinateList':wkObj.coordinateList,
          'isCurrent': this.currentDispRouteImg == wkObj.img ? true:false
        })
      }
      this.rockImgList = wkList
    },
    /*--------------------------
      ルートをハイライト表示する
    --------------------------*/ 
    async routeHighlight(item){
      this.imgHeaderMsg = 'ルート名：' + item.routeName
      this.drawHighlightRoute(item)
      if(!this.isShowingRouteLine){
        this.isShowingRouteLine = true
        //画像上の全ルート線を表示する(ハイライト表示はしない)
        this.drawAllRoute(this.currentRouteList)
      }
      //ルート名一覧を更新
      let wkList = []
      this.dispRouteList.forEach(function(data){
        let addObj = {
          'routeId':data.routeId,
          'level':data.level,
          'routeAuthor':data.routeAuthor,
          'routeName':data.routeName,
          'routeCoordinate':data.routeCoordinate,
          'labelCoordinate':data.labelCoordinate,
          'isRouteActive': data.routeId == item.routeId ? true  :false,
          'no':data.no,
          'isShowMakeRecordIcon':false
        }
        wkList.push(addObj)
      })
      this.dispRouteList = wkList

      //対象行の記録作成ボタンを表示する
      this.dispRouteList[item.no-1].isShowMakeRecordIcon = true

    },
    /*------------------------------
      ルートの表示、非表示を切り替える
    --------------------------------*/ 
    switchDrawRoute(){
      if(this.isShowingRouteLine){

        //既に描画済みの場合はリセットする。
        this.isShowingRouteLine = false
        //全ルート表示用、ハイライト用のキャンバスをクリアする
        this.routeHighlightCanvasClear()
        //ルート名一覧の背景色着色をクリア
        let wkList = []
        this.dispRouteList.forEach(function(data){
          let addObj = {
            'routeId':data.routeId,
            'level':data.level,
            'routeAuthor':data.routeAuthor,
            'routeName':data.routeName,
            'routeCoordinate':data.routeCoordinate,
            'labelCoordinate':data.labelCoordinate,
            'isRouteActive': false,
            'no':data.no
          }
          wkList.push(addObj)
        })
        this.dispRouteList = wkList

        return
      }else{ 
        this.isShowingRouteLine = true
        //画像上の全ルート線を表示する(ハイライト表示はしない)
        this.drawAllRoute(this.currentRouteList)
      }
    },
    /*--------------------------
      岩場画面への遷移
    --------------------------*/ 
    callRockyInfo(){
      this.$router.push('/rockyInfo/' + this.rockyId)
    },    
    /*-----------------------------------------------
      ルートList画面への遷移
      画面上の「戻る」押下時、直接アクセス時に実行される
    -------------------------------------------------*/ 
    callRouteList(){
      this.$router.push({
        name: 'rockyInfo_:rockyId_:rockId',
        params:{
          rockId:this.rockId,
          rockyId:this.rockyId,
          rockyDetailJson:this.rockyDetailJson
        },
      })
    },
    /*--------------------------
      ルート座標のデータ取得
    --------------------------*/ 
    async getRouteCoordinateData(){
      // ルート座標情報を取得
      let json = await this.getRouteCoordinateList(this.rockId)
      if (json) {
        if(json.data.length==0){
          //対象のデータが存在しない場合
          this.routeDataErrorProcess()
          return
        }
      }

      //APIから取得したデータ
      this.routeDataListJson = json.data
      //初期表示時にハイライト表示するルートID
      let currentRouteId = this.routeId
      let currentRouteImg = ''
      let currentCoordinateList = []

      //処理に使う形式にセットしなおす
      let wkList = []
      let wkRemarkList = []
      let wkFlg = false
      //APIから取得したデータ全件ループして各種処理を行う。
      this.routeDataListJson.forEach(function(record) {
        record.coordinateList.forEach(function(coordinate){
          //初期表示時にハイライト表示するルートのデータを抽出する
          //1ルートのデータが複数の画像に存在する場合があるが、
          //暫定で画像ファイル名の昇順トップのデータを採用する
          if(coordinate.routeId == currentRouteId && currentRouteImg == ''){
            currentRouteImg = record.img
            currentCoordinateList = record.coordinateList
          }
          //特記事項のセット
          if(coordinate.remarks != undefined){
            wkRemarkList.push(coordinate.remarks)
          }
          //ルート線表示ボタンのフラグセット
          //(表示できるルートやフラグが1つもない場合、ボタンは非表示にする)
          if(coordinate.routeCoordinate != null){
            wkFlg = true
          }
        })

        //カルーセルに表示する岩画像のListをセットする
        wkList.push({
          'img':record.img,
          'coordinateList':record.coordinateList,
          'isCurrent': currentRouteImg == record.img ? true:false
        })
      })

      this.remarkList = wkRemarkList
      this.isShowDrawRouteButton = wkFlg

      if(currentRouteImg=='' || currentCoordinateList==[]){
        //座標データが存在しない場合や表示できる画像が存在しない場合は
        //エラーメッセージを表示する
        this.routeDataErrorProcess()
        return
      }       

      this.currentDispRouteImg = currentRouteImg
      this.currentRouteList = currentCoordinateList
      this.rockImgList = wkList

      //ルート名一覧のListにセット
      this.dispRouteList = this.makeRouteInfoList(this.currentRouteList,this.routeId)
      //ハイライト対象(前画面で選択されたルート)をObjectにセットする
      this.highlightRouteObj = this.dispRouteList.filter(rt => rt.routeId == this.routeId)[0]

      this.isDataExist = true
    },
    //---------------
    // 岩場データ取得
    //---------------
    getRockyMasterData: (paramRockyId,isLogin) => new Promise((resolve, reject) => {
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-rocky/rocky-info?rockyId=' + paramRockyId + '&isLogin=' + isLogin
      axios.get(jsonUrl)
      .then(res => resolve(res))
      .catch(err => reject(err))
    }),
    //------------------------------
    //  APIから岩場のルートを取得する
    //------------------------------
    getRouteListData: (rockId) => new Promise((resolve, reject) => {
      let query = '?rockId=' + rockId
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-rocky/route-list' + query
      axios.get(jsonUrl)
      .then(res => resolve(res))
      .catch(err => reject(err))
    }), 
    //----------------------------
    //  APIから座標データを取得する
    //----------------------------
    getRouteCoordinateList: (rockId) => new Promise((resolve, reject) => {
      let query = '?rockId=' + rockId
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-rocky/route-coordinate-list' + query
      axios.get(jsonUrl)
      .then(res => resolve(res))
      .catch(err => reject(err))
    }),
    //---------------------
    // ルート情報の取得処理
    //---------------------
    async getRockyMasterAndRouteDataList(){

      //岩情報取得
      //パラメータから岩場情報が取得できなかった場合は取得する
      //TODO:パラメータで受け取ったJSONの整合性チェック(検討する)
      if(this.rockyDetailJson == undefined){
        let json = await this.getRockyMasterData(this.rockyId,this.isLogin)
        if (json) {
          if(json.data.length==0){
            this.routeDataErrorProcess()
            return
          }
          this.rockyDetailJson = json.data[0]
        }
      }

      this.rockyName = this.rockyDetailJson['rockyName']
      let rockList = this.rockyDetailJson.rockList

      //ルートテーブルを取得する
      await this.getRouteListData(this.rockId)
      .then(response => {
        this.dbRouteList = response.data
      })
      .catch(function (error) {
        console.log('getRouteListData() error')
      })

      //岩Listから対象の岩の情報を抽出する
      let targetRockData = rockList.find(element => element.id == this.rockId)
      if(!targetRockData){
        this.$router.push('/rockyInfo/' + this.rockyId)
      }

      //岩名
      this.rockName = targetRockData.name

      //ルート一覧の値をセットする
      this.routeIdNameList = (() => {
        let routeList = []
        let count = 0
        this.dbRouteList.forEach((route) => {
          routeList.push({
            no: ++count,
            routeId: route.routeId,
            route_name: route.routeName,
            level: route.gradeName,
            route_author: ''
          })
        })

        return routeList
      })()
    },
    /*--------------------------
      ルート線の描画
    --------------------------*/ 
    async callDrawRoute(){

      const drawHilight = () => {
        //canvasに描画する
        this.routeHighlight(this.highlightRouteObj)
      }

      this.drawAllRouteAndHighlight(this.currentRouteList,drawHilight)
      this.isShowCarousel = true

    },
    /*--------------------------
      データが存在しない場合の処理
    --------------------------*/ 
    async routeDataErrorProcess(){
      await this.dispErrorDialog()
      this.$router.push('/rockyInfo/' + this.rockyId)
    },
    /*--------------------------
      エラーメッセージ表示
    --------------------------*/ 
    async dispErrorDialog(){
      await this.asyncDialog('存在しないルート、または、データ準備中です。')
      //ルート一覧画面にもどる
      this.$router.push('/rockyInfo/' + this.rockyId)
    },
    asyncDialog(msg) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk(msg, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
        .catch(err => {
          // An error occurred
        })
      })
    },
    /*------------------------------------
      画像上の全ルートとハイライトを表示する
    --------------------------------------*/
    async drawAllRouteAndHighlight(paramList,drawHilight) {
      this.drawAllRoute(paramList)
      drawHilight()
    },
    /*--------------------------
      画像上の全ルートを表示する
    --------------------------*/
    async drawAllRoute(paramList) {
      this.currentRouteList = paramList
      // List分処理する
      for (let i = 0; i < paramList.length; i++) {
        let routeList = JSON.parse(paramList[i].routeCoordinate)
        let labelList = JSON.parse(paramList[i].labelCoordinate)
        //ルート線を描画
        this.drawRouteLine('route-canvas'
                  ,routeList,this.ROUTE_LINE_COLOR,this.ROUTE_LINE_WIDTH
                  ,labelList,this.LABEL_TEXT_COLOR,this.LABEL_COLOR,this.LABEL_STYLE)
      }
    },
    /*--------------------------
      ルート線をハイライト表示する
    --------------------------*/
    drawHighlightRoute(param) {
      //表示中のハイライトが存在する場合があるので1度クリアする
      this.canvasClear('route-highlight-canvas')

      let routeList = JSON.parse(param.routeCoordinate)
      let labelList = JSON.parse(param.labelCoordinate)
      //ルート線を描画
      this.drawRouteLine('route-highlight-canvas'
                ,routeList,this.ROUTE_LINE_HIGHLIGHT_COLER,this.ROUTE_LINE_HIGHLIGHT_WIDTH
                ,labelList,this.LABEL_TEXT_HIGHLIGHT_COLOR,this.LABEL_HIGHLIGHT_COLER,this.LABEL_STYLE)
    },
    /*-------------------
    ルートの線を描画する
    parameter:
      canvasId:canvasのid
      routeList:ルートの座標List
      routeLineColor:ルート線の描画色
      routeLineWidth:ルート線の太さ(px)
      labelList:ラベルの座標List
      labelColor:ラベルの描画色
      labelFontStyle:ラベルのフォント指定
    -------------------*/
    drawRouteLine(canvasId
                ,routeList,routeLineColor,routeLineWidth
                ,labelList,labelColor,labelBackGroundColor,labelFontStyle){

      //表示する画像のオリジナルサイズを取得する
      var img = new Image()
      img.src = this.makeImgPath(this.currentDispRouteImg)
      this.imgOrignalWidth  = img.width
      this.imgOrignalHeight = img.height

      let targetCanvas = document.getElementById(canvasId)
      let ctx = targetCanvas.getContext('2d')
      //画像をブラウザのサイズに併せてリサイズしているので、
      //同様に座標もリサイズして表示できるよう倍率を計算する。
      let magnX = targetCanvas.width/img.width
      let magnY = targetCanvas.height/img.height
      //ルート描画
      ctx.strokeStyle = routeLineColor //線の色
      ctx.lineWidth = routeLineWidth //線の太さ
      ctx.lineCap = "round" //先端を丸くする

      if(routeList != null){
        routeList.forEach(function(route) {
          ctx.beginPath()
          //描画開始の座標に移動
          ctx.moveTo(route[0][0] * magnX, route[0][1] * magnY)
          //描画する座標を指定
          route.forEach(function(coordinate) {
            ctx.lineTo(coordinate[0]* magnX, coordinate[1]* magnY)
          })
          //描画実行する
          ctx.stroke()
        })
      }
      //ルートNoの描画
      if(labelList != null){
        labelList.forEach(function(routeNo) {
          //ラベルの可視性を高める為、枠を付ける
          //四角形（輪郭）
          //ctx.strokeStyle="red"
          //ctx.strokeRect(routeNo[0]*magnX-3, routeNo[1]*magnY-18, 17, 20)
          //四角形（塗りつぶし）
          ctx.fillStyle = labelBackGroundColor
          ctx.fillRect(routeNo[0]*magnX-3, routeNo[1]*magnY-14, 17, 17)

          ctx.fillStyle = labelColor //ラベルの色
          ctx.font = labelFontStyle //ラベルのフォントスタイル
          ctx.fillText(routeNo[2], routeNo[0]*magnX, routeNo[1]*magnY)
        })
      }
    },
    /*-----------------------------------------------
      岩IDに紐付くルート名やレベルなどをListにセットする
      ※ルート名、レベルは遷移元画面から受け取ったデータを使用する。
      param:
        routeList:1画像分のルートList
        highlightRouteId:ハイライト対象のルートId(ハイライトしない場合は''をセット)
    -------------------------------------------------*/
    makeRouteInfoList(routeList,highlightRouteId){
      let routeIdNameList = this.routeIdNameList
      let returnList = []
      //ルート数分処理
      let cnt = 0
      routeList.forEach(function(record) {

        ++cnt

        //ルートIDに紐付くルート名、レベルを抽出する
        let wkObj = routeIdNameList.filter(rt => rt.routeId == record.routeId)
        //ハイライト対象かどうかのフラグ
        let isActive = false
        if(record!=''){
          if(record.routeId == highlightRouteId){
            isActive = true
          }
        }

        //暫定でルートのラベルは連番にする
        let wkNumberedLabelList = []
        //[[77,236,"C"]]
        //alert(JSON.parse(record.labelCoordinate))
        // let labelC = JSON.parse(record.labelCoordinate).toString().split(',')
        // let x = labelC[0]
        // let y = labelC[1]
        // let label = cnt
        // let wkLabelCoordinate = x + ',' + y +',' + label

        let routeName = wkObj[0].route_name == null || wkObj[0].route_name == '' ? '（ルート名無し）' : wkObj[0].route_name

        let addObj = {
          'routeId':record.routeId,
          'level':wkObj[0].level,
          'routeAuthor':wkObj[0].route_author,
          'routeName':routeName,
          'routeCoordinate':record.routeCoordinate,
          'labelCoordinate':record.labelCoordinate,
          'isRouteActive':isActive,
          'no':cnt
        }
        returnList.push(addObj)

      })
      return returnList
    },
    /*-------------------------------------------------------------
      全ルート表示用、ハイライト表示用のキャンバスの表示内容をクリアする
    ---------------------------------------------------------------*/
    async routeHighlightCanvasClear(){
      this.canvasClear('route-canvas')
      this.canvasClear('route-highlight-canvas')
    },
    canvasClear(canvasId){
      let canvas = document.getElementById(canvasId)
      let ctxCanvas = canvas.getContext('2d')
      ctxCanvas.clearRect(0, 0, canvas.width, canvas.height)
    },
    /*--------------------------
      画像のパス生成
    --------------------------*/  
    makeImgPath(imgPath){
      return process.env.VUE_APP_MATERIAL_URL + imgPath
    },
    makeImgThumbPath(imgPath){
      return this.getRockThumbPath(imgPath,this.rockyId,'120')
    },
  },
  // コンポーネント
  components: {
    Carousel,
    Loading,
    Slide
  }
}

</script>

<style lang="stylus" scoped>
#app .wrapper
  padding-top 0px

#title
  margin-right -15px
  margin-left -15px
  text-align center
  color #fff
  background #03375c
  font-size 1.5rem
  padding 1.1rem
  text-align center
  position relative

.plane-background
  background linear-gradient(3deg, rgba(0,65,112,1) 0%, rgba(9,9,121,1) 34%, rgba(16,70,112,1) 100%)
  height 100vh

//コンテナ群
#app
  .wrapper
    min-height 90vh

#arrow
  margin 1rem 0
  i.fas.fa-angle-left
    line-height 20px
  .btn-link
    font-size 20px
    color #374047

#canvas-header
  background #171D32
  color #fff
  font-size 1.2rem
  padding-left 5px
  width 100%
  height 30px

//************************** */
  #canvas-container
    position relative
    canvas
      position absolute
      top 0
      left 0
      width 100%
  #rocky-img-canvas
    z-index 1
  #route-canvas
    z-index 2
  #route-highlight-canvas
    z-index 3
  #canvas-container-wrapper
    max-height 720px
    max-width 100%
    overflow-x scroll    
    overflow-y scroll
    &::-webkit-scrollbar
      width 5px
      height 5px
    &::-webkit-scrollbar-track
      border-radius 5px
      box-shadow 0 0 4px #aaa inset
    &::-webkit-scrollbar-thumb
      border-radius 5px
      //background linear-gradient(#0664a9,#042654)
      background linear-gradient(#c9c9c9,#4d4c4c)
    &::-webkit-scrollbar-track
      border-radius 5px
      box-shadow 0 0 4px #aaa inset
    //MDNでは非推奨。
    //https://developer.mozilla.org/ja/docs/Web/CSS/-webkit-overflow-scrolling
    -webkit-overflow-scrolling touch

//カルーセル
.VueCarousel
  min-height 110px
  max-height 160px

.VueCarousel-wrapper, .VueCarousel-inner, .VueCarousel-slide
  height 100% !important

.VueCarousel-slide .slider-inner 
  height 90px
  display flex 
  justify-content center
  align-items center
  color #fff

.VueCarousel-slide
  display flex 
  justify-content center
  align-items center
  background #374047
  margin 0 2px
  cursor pointer

#route-img-list-wrapper
  height 150px
#route-img-list
  margin-top 10px
  margin-bottom 20px
  h6
    color #b8c9d4
.thumbnail-wrapper
  position relative
  opacity 0.7
  transition-duration 0.3s

//選択中の画像のstyle
.currentImg
  background #171d32
  img
    filter brightness(115%)

.slide-img,.thumbnail-info
  width 100%
  cursor pointer

.thumbnail-info
  height 45%
  position absolute
  bottom 0
  background-color rgba(0, 0, 0, 0.5)
  .climbing-date
    padding-left 0.5rem
    font-size 0.7rem
    color #B7C4CF
  .rocky-name
    color white
    padding-left 0.5rem
    font-size 1rem
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
  .recommend-rocky-name
    padding-left 0.5rem
    font-size 1rem
    color white
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
  .recommend-rocky-revel
    color #00B0FF
    text-align right
    padding-right 0.8rem
    padding-bottom 1rem
    font-size 0.8rem

#list-button-container
  margin-bottom 2rem

//特記事項
#info-container
  background #e2eff9
  color #2d8fd5
  margin 5px 0
  padding 5px
  border-radius 3px

//ルートリスト
.list-group-item 
  padding 0.7rem 0.5rem
  cursor pointer
.listGroupItemActive
  background #d2e6fe

.list-innner
  width 100%
  display flex
  .list-no
    width 10%
    padding-left 5px
　.list-route-name
    width 60%
  .list-level
    width 20%

  .list-make-record
    width 10%
    .inner-box
      width 50%
      text-align center
      position relative
      display inline-block
      cursor pointer
      .list-make-recordtext 
        position absolute
        z-index 1
        bottom 100%
        left -25%
        visibility hidden
        width auto
        white-space nowrap
        padding 0.3em 0.5em
        transition opacity 1s
        text-align center
        opacity 0
        color #ffffff
        border-radius 2px
        background-color #606e79
        font-size 0.8rem
      &:hover 
        .list-make-recordtext 
          visibility visible
          opacity 1

#draw-route-button-area
  margin 1.5rem 0

// モバイルデバイス
@media (max-width 992px)
  .list-innner
    .list-make-record
      .list-make-recordtext 
        display none

// 大デバイス（デスクトップ, 1170px 以上）
@media (min-width 1170px)
  #route-list-container
    padding-top 50px

  .VueCarousel-slide
    border-right 3px solid white
  
  #route-canvas-row
    margin-bottom 15px

  #route-img-list
    h6
      color #2c3e50

</style>